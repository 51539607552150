// creating temporary file to store data manually before backend is created;

const surveyData = {
  selectOpt : {
    mealCount : [{val:3, text:"Three"},{val:5, text:"Five"},{val:2, text:"Two"}],
    planType :[{val:7, text:"Weekly"},{val:1, text:"Daily"}],
  },
  dietSpec : [
    {name: "balanced", text: "Balanced Diet (Protein/Fat/Carb values in 15/35/50 ratio)"},
    {name: "low-carb", text: "Low-Carb (Less than 20% of total calories from carbs)"},
    {name: "low-fat", text: "Low-Fat (Less than 15% of total calories from fat)"},
    {name: "high-fiber", text: "High Fiber (More than 5g fiber per serving)"},
    {name: "high-protein", text: "High Protien (More than 50% of total calories from proteins)"},
    {name: "low-sodium", text: "Low-Sodium (Less than 140mg Na per serving)"},
  ],
  healthSpec : [
    {name: "vegan", text: "Vegan (No meat, poultry, fish, dairy, eggs or honey)"},
    {name: "vegetarian", text: "Vegetarian (No meat, poultry, or fish)"},
    {name: "alcohol-free", text: "Alcohol-free (No alcohol used or contained)"},
    {name: "peanut-free", text: "Peanut Free (No peanuts or products containing peanuts)"},
    {name: "wheat-free", text: "Wheat Free (No wheat, can have gluten though)"},
    {name: "paleo", text: "Paleo (Excludes what are perceived to be agricultural products; grains, legumes, dairy products, potatoes, refined salt, refined sugar, and processed oils)"},
    {name: "fish-free", text: "Fish Free (No fish or fish derivatives)"},
    {name: "DASH", text: "Dietary Approaches to Stop Hypertension diet"},
    {name: "Mediterranean", text: "Mediterranean diet"},
  ],
  mealTypes : {
    3: ["Breakfast","Lunch","Dinner"],
    5: ["Breakfast Snack","Breakfast","Lunch","Afternoon Snack","Dinner"],
    2: ["Brunch","Dinner"],
  },
  calories : {
    min:1800,
    max:2500,
  },
}
const API = {
  ID : "7f8ec36c",
  KEY : "c4b6990104fb1afd81b3c017b8c9f209",
  URL : "https://api.edamam.com/search?",
}
export {
  surveyData as Survey,
  API,
}
