import React from 'react';
import './Footer.css';

const Footer = () => (
  <div className="Footer">
    <p>
    </p>
  </div>
);

export default Footer;
